import React , {  useState , useEffect} from 'react';
import { useNavigate, useParams} from "react-router-dom";  
import '../style_school.css';
import {useApiData,IconsColor} from "../../functions/useApiData"

function DeckForm(props) {

    const [allData, setAllData] = useState([]);
    const [codeVivenda, setCodeVivenda] = useState('0000096857');
    const [refCantrato, setRefContrato] = useState('');
    const [dateContratoo, setDateContratoo] = useState('2025-02-22');
    const [dateIn, setDateIn] = useState('2025-02-22T16:00:00');
    const [dateOut, setDateOut] = useState('2025-02-22T12:00:00');
    const [numPersonas, setNumPersonas] = useState('2');
    const [numHabitaciones, setNumHabitaciones] = useState('3');
    const [conInternet, setConInternet] = useState('1');
    // PAGO
    const [formaPago, setFormaPago] = useState('TARJT');
    const [datePago, setDatePago] = useState('');
    const [numeroPago, setNumeroPago] = useState('');
    const [personPago, setPersonPago] = useState('');
    const [caducaCard, setCaducaCard] = useState('');

    const [getPlatform, setPlatform] = useState('');
    const [getRef, setRef] = useState('');
    const [getUID, setUID] = useState('');
    const [getStatus, setStatus] = useState('');

    const navigate = useNavigate();
    // const login_token = useSelector((state)=> state.counter.user.login_token);
    const login_token = localStorage.getItem('login_token');
    const activeProjectId = localStorage.getItem('projectid');
    const activeParteId = localStorage.getItem('parteid');


    useEffect(() => {
          // перевірити валідність токену
          if (activeParteId != "NEW"){

            fetch('https://www.nebo.inupline.com/api/web/vut/getPartesViajeros/' + activeParteId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + login_token,
                },
            })
                .then(response => response.json())
                .then(response => {
                    
                    if (response.status===200) {
                        console.log("type OK activeParteId-" , response.PARTE[0])
                        setAllData(response);
                        setCodeVivenda(response.PARTE[0].CODEST);
                        setPlatform(response.PARTE[0].PLATFORM);
                        setRef(response.PARTE[0].REF);
                        setUID(response.PARTE[0].UID);
                        setStatus(response.PARTE[0].STATUS);
                        setRefContrato(response.PARTE[0].REFCONTRATO);
                        setDateContratoo(response.PARTE[0].DATECONTRATO);
                        setDateIn(response.PARTE[0].CHECKIN);
                        setDateOut(response.PARTE[0].CHECKOUT);
                        setNumPersonas(response.PARTE[0].CLIENTE);
                        setNumHabitaciones(response.PARTE[0].HABITACIONES);
                        setConInternet(response.PARTE[0].INTERNET);
                        setFormaPago(response.PARTE[0].TYPEPAGO);
                        setDatePago(response.PARTE[0].DATEPAGO);
                        setNumeroPago(response.PARTE[0].NUMBER);
                        setPersonPago(response.PARTE[0].TITULAR);
                        setCaducaCard(response.PARTE[0].DATECARD);
                    } else if (response.status===409) {
                        console.log("type KO-activeParteId:" , response)
                    }
                });  
            }
        }, [activeParteId]); // AUTOLOAD
    
    

     // Manejadores de cambios para actualizar los estados
    const handleCodeHomeChange = (e) => {
        setCodeVivenda(e.target.value);
    };

    const handPlatformChange = (e) => {
        setPlatform(e.target.value);
    };

    const handRefChange = (e) => {
        setRef(e.target.value);
    };

    const handUIDChange = (e) => {
        setUID(e.target.value);
    };

    const handStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const handRefContratoChange = (e) => {
        setRefContrato(e.target.value);
    };

    const handleDateContratoChange = (e) => {
        setDateContratoo(e.target.value);
    };

    const handleDateInChange = (e) => {
        setDateIn(e.target.value);
    };

    const handleDateOutChange = (e) => {
        setDateOut(e.target.value);
    };

    const handleNumPersonChange = (e) => {
        setNumPersonas(e.target.value);
    };

    const handleNumHabitacionesChange = (e) => {
        setNumHabitaciones(e.target.value);
    };

    const handleConInternetChange = (e) => {
        setConInternet(e.target.value);
    };

    const handleFormaPagoChange = (e) => {
        setFormaPago(e.target.value);
    };

    const handleDatePagoChange = (e) => {
        setDatePago(e.target.value);
    };

    const handleNumeroPagoChange = (e) => {
        setNumeroPago(e.target.value);
    };

    const handlePersonPagoChange = (e) => {
        setPersonPago(e.target.value);
    };

    const handleCaducaCardChange = (e) => {
        setCaducaCard(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const userData = {
            VUTP_PROJECTID: activeProjectId,
            VUTP_PLATFORM: getPlatform,
            VUTP_REF: getRef,
            VUTP_UID: getUID,
            VUTP_STATUS: getStatus,
            VUTP_CODEST: codeVivenda,
            VUTP_REFCONTRATO: refCantrato,
            VUTP_DATECONTRATO: dateContratoo,
            VUTP_CHECKIN: dateIn,
            VUTP_CHECKOUT: dateOut,
            VUTP_CLIENTES: numPersonas,
            VUTP_HABITACIONES: numHabitaciones,
            VUTP_INTERNET: conInternet,
            VUTP_TYPEPAGO: formaPago,
            VUTP_DATEPAGO: datePago,
            VUTP_NUMBER: numeroPago,
            VUTP_TITULAR: personPago,
            VUTP_DATECARD: caducaCard,
            VUTP_PROCESID: "1"
        };

        fetch(`https://www.nebo.inupline.com/api/web/vut/postAddParte${activeParteId !== "NEW" ? '/' + activeParteId : ''}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData)
        })

      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            console.log("postAddParte", response)
            //navigate(`/projects`)
            // dispatch(application.setUpdateTime(response.update));
            navigate(-1);
          } else {
            // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
            console.log("postAddParte" , response)
            navigate(-1);
        }
      });
    };

    function generateXMLFromData(apiResponse) {
        const parte = apiResponse.PARTE[0]; // Беремо перший об'єкт із масиву PARTE
        let xml = `<ns2:peticion xmlns:ns2="http://www.neg.hospedajes.mir.es/altaParteHospedaje">\n`;
        xml += `  <solicitud>\n`;
        xml += `    <codigoEstablecimiento>${parte.CODEST}</codigoEstablecimiento>\n`;
        xml += `    <comunicacion>\n`;
        xml += `      <contrato>\n`;
        xml += `        <referencia>${parte.REFCONTRATO}</referencia>\n`;
        xml += `        <fechaContrato>${parte.DATECONTRATO}</fechaContrato>\n`;
        xml += `        <fechaEntrada>${parte.CHECKIN.replace(' ', 'T')}</fechaEntrada>\n`;
        xml += `        <fechaSalida>${parte.CHECKOUT.replace(' ', 'T')}</fechaSalida>\n`;
        xml += `        <numPersonas>${parte.CLIENTE}</numPersonas>\n`;
        xml += `        <numHabitaciones>${parte.HABITACIONES}</numHabitaciones>\n`;
        xml += `        <internet>${parte.INTERNET === 1 ? 'true' : 'false'}</internet>\n`;
        xml += `        <pago>\n`;
        xml += `          <tipoPago>${parte.TYPEPAGO}</tipoPago>\n`;
        xml += `          <fechaPago>${parte.DATEPAGO}</fechaPago>\n`;
        xml += `          <medioPago>${parte.NUMBER}</medioPago>\n`;
        xml += `          <titular>${parte.TITULAR}</titular>\n`;
        xml += `          <caducidadTarjeta>${parte.DATECARD}</caducidadTarjeta>\n`;
        xml += `        </pago>\n`;
        xml += `      </contrato>\n`;
    
        // Додаємо мандрівників
        parte.VIAJEROS.forEach(viajero => {
            xml += `      <persona>\n`;
            xml += `        <rol>${viajero.ROLE}</rol>\n`;
            xml += `        <nombre>${viajero.NAME}</nombre>\n`;
            xml += `        <apellido1>${viajero.APEDIDO}</apellido1>\n`;
            xml += `        <apellido2>${viajero.APEDIDO2}</apellido2>\n`;
            xml += `        <tipoDocumento>${viajero.DOCUMENTO}</tipoDocumento>\n`;
            xml += `        <numeroDocumento>${viajero.NUMDOCUMENTO}</numeroDocumento>\n`;
            xml += `        <soporteDocumento>${viajero.NUMSOPORTE}</soporteDocumento>\n`;
            xml += `        <fechaNacimiento>${viajero.DATABIRDAY}</fechaNacimiento>\n`;
            xml += `        <nacionalidad>${viajero.NATIONALIDAD}</nacionalidad>\n`;
            xml += `        <sexo>${viajero.SEXO}</sexo>\n`;
            xml += `        <direccion>\n`;
            xml += `          <direccion>${viajero.DIRECTION1}</direccion>\n`;
            xml += `          <direccionComplementaria>${viajero.DIRECTION2}</direccionComplementaria>\n`;
            xml += `          <codigoMunicipio>${viajero.DIRCODE}</codigoMunicipio>\n`;
            xml += `          <nombreMunicipio>${viajero.DIRMUNICIPIO}</nombreMunicipio>\n`;
            xml += `          <codigoPostal>${viajero.ZIP}</codigoPostal>\n`;
            xml += `          <pais>${viajero.PAIS}</pais>\n`;
            xml += `        </direccion>\n`;
            xml += `        <telefono>${viajero.TELEFONO}</telefono>\n`;
            xml += `        <telefono2>${viajero.TELEFONO2}</telefono2>\n`;
            xml += `        <correo>${viajero.EMAIL}</correo>\n`;
            xml += `        <parentesco>${viajero.PARENTESCO}</parentesco>\n`;
            xml += `      </persona>\n`;
        });
    
        xml += `    </comunicacion>\n`;
        xml += `  </solicitud>\n`;
        xml += `</ns2:peticion>`;
    
        console.log(xml); // Показує XML у консолі
    
        // Додаємо можливість завантаження файлу XML
        const blob = new Blob([xml], { type: 'application/xml' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'parte.xml';
        link.click();
    }

    return (
        <div className="form_0">
            <form onSubmit={handleSubmit}>
                {/* <input type="hidden" name="userid" readOnly={"user_id"} /> */} 
                <label className="desc" htmlFor="CodeHomeChange">
                    Código Establecimiento:
                </label>
                <div className="input_field select_option">
                    <select id="CodeHomeChange" name ="CodeHomeChange" value={codeVivenda} onChange={handleCodeHomeChange} required>
                        <option value={null}>choice</option>
                        <option value="0000096857" select>Faro28 / 0000096857</option>

                    </select>
                    <div className="select_arrow"></div>
                </div>

                <label className="desc" htmlFor="NumPersonChange">Platforma de reserva:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="NumPersonChange" name="NumPersonChange" type="text" placeholder="Platforma de reserva:" value={getPlatform} onChange={handPlatformChange}/>
                </div>

                <label className="desc" htmlFor="NumRefChange">Referencia de justificante :</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="NumRefChange" name="NumRefChange" type="text" placeholder="Referencia de justificante:" value={getRef} onChange={handRefChange}/>
                </div>

                <label className="desc" htmlFor="NumUIDChange">UID de reserva:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="NumUIDChange" name="NumUIDChange" type="text" placeholder="UID de reserva:" value={getUID} onChange={handUIDChange}/>
                </div>

                <label className="desc" htmlFor="RefContratoChange">Referencia Contrato:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="RefContratoChange" name="RefContratoChange" type="text" placeholder="Código Establecimiento ej: 0222154" value={refCantrato} onChange={handRefContratoChange} required/>
                </div>
                
                <div className="input_field"> 
                    <label className="desc" htmlFor="DateContratoChange">Fecha Contrato:</label>
                    <div className="input_field"> <span><i aria-hidden="true" className="fa fa-sign-in"></i></span>
                        <input type="date" id="DateContratoChange" name="DateContratoChange" value= {dateContratoo} min="2025-01-01"  onChange={handleDateContratoChange} required/>
                    </div>
                </div>

                <div className="input_field"> 
                    <label className="desc" htmlFor="DateInChange">Fecha Entrada:</label>
                    <div className="input_field"> <span><i aria-hidden="true" className="fa fa-sign-in"></i></span>
                        <input type="datetime-local" id="DateInChange" name="DateInChange" value= {dateIn} min="2025-01-01"  onChange={handleDateInChange} required/>
                    </div>
                </div>

                <div className="input_field"> 
                    <label className="desc" htmlFor="DateOutChange">Fecha Salida:</label>
                    <div className="input_field"> <span><i aria-hidden="true" className="fa fa-sign-in"></i></span>
                        <input type="datetime-local" id="DateOutChange" name="DateOutChange" value= {dateOut} min="2025-01-01"  onChange={handleDateOutChange} required/>
                    </div>
                </div>

                <label className="desc" htmlFor="NumPersonChange">Número Personas:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="NumPersonChange" name="NumPersonChange" type="text" placeholder="Número Personas" value={numPersonas} onChange={handleNumPersonChange} required/>
                </div>

                <label className="desc" htmlFor="NumHabitacionesChange">Habitaciones:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="NumHabitacionesChange" name="NumHabitacionesChange" type="text" placeholder="Número Habitaciones" value={numHabitaciones} onChange={handleNumHabitacionesChange} required/>
                </div>

                <label className="desc" htmlFor="ConInternetChange">Internet:</label>
                <div className="input_field select_option">
                    <select id="ConInternetChange" name ="ConInternetChange" value={conInternet} onChange={handleConInternetChange} required>
                        <option value="1">Si</option>
                        <option value="0">No</option>
                    </select>
                    <div className="select_arrow"></div>
                </div>

                <br></br><b>PAGO</b> <br></br>

                <label className="desc" htmlFor="FormaPagoChang">Tipo de pago:</label>
                <div className="input_field select_option">
                    <select id="FormaPagoChang" name ="FormaPagoChang" value={formaPago} onChange={handleFormaPagoChange} required>
                        <option value="EFECT">Efectivo</option>
                        <option value="TARJT">Tarjeta de crédito</option>
                        <option value="PLATF">Plataforma de pago</option>
                        <option value="TRANS">Transferencia</option>
                        <option value="MOVIL">Pago por móvil</option>
                        <option value="TREG">Tarjeta regalo</option>
                        <option value="DESTI">Pago en destino</option>
                        <option value="OTRO">Otros medios de pago</option>
                    </select>
                    <div className="select_arrow"></div>
                </div>

                <div className="input_field"> 
                    <label className="desc" htmlFor="DatePagoChange">Fecha de pago:</label>
                    <div className="input_field"> <span><i aria-hidden="true" className="fa fa-sign-in"></i></span>
                        <input type="date" id="DatePagoChange" name="DatePagoChange" value= {datePago} min="2025-01-01" onChange={handleDatePagoChange} required/>
                    </div>
                </div>

                <label className="desc" htmlFor="NumeroPagoChange">Numero de tarjeta:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="NumeroPagoChange" name="NumeroPagoChange" type="text" placeholder="Numero de tarjeta o cuenta" value={numeroPago} onChange={handleNumeroPagoChange} required maxLength={50}/>
                </div>

                <label className="desc" htmlFor="PersonPagoChange">Titular del pago:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="PersonPagoChange" name="PersonPagoChange" type="text" placeholder="Titular del tarjeta o cuenta" value={personPago} onChange={handlePersonPagoChange} required/>
                </div>

                <label className="desc" htmlFor="CaducaCardChange">Fecha de caducidad MM/YYYY:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="CaducaCardChange" name="CaducaCardChange" type="text" placeholder="MM/YYYY" value={caducaCard} onChange={handleCaducaCardChange} required/>
                </div>

                <label className="desc" htmlFor="ConStatusChange">Status:</label>
                <div className="input_field select_option">
                    <select id="ConStatusChange" name ="ConStatusChange" value={getStatus} onChange={handStatusChange} required>
                        <option value="1">Bloqueado</option>
                        <option value="0">Activo</option>
                    </select>
                    <div className="select_arrow"></div>
                </div>

                {/* <label className="desc" id="typeid_l" htmlFor="typeid">
                    Fecha Contrato:
                </label>
                <div className="input_field select_option">
                    <select id="typeid" value={templateHTML} onChange={handleLevelChange}>
                        <option value={"0"} key={0}>Standart</option>
                    </select>
                    <div className="select_arrow"></div>
                </div>

                <label className="desc" id="typeid_l" htmlFor="typeid">
                    Fecha Entrada:
                </label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-tag"></i></span>
                    <input id="note" name="note" type="text" placeholder="Idioma" value={note} onChange={handleLNGNameChange}/>
                </div>

                <label className="desc" id="typeid_l" htmlFor="typeid">
                    Fecha Salida ( Всього класів: {countclassGroup} ):
                </label>
                <div className="input_field select_option">
                    <select value={groupid} onChange={handleGroupIDChange}>
                            <option value={null}>
                                Весь проект (Запрошення для персоналу )
                            </option>
                        {classGroup.map((filename, index) => (
                            <option key={index} value={filename.groupid}>
                                {filename.cursname} - {filename.groupname} ({filename.cursnote} / {filename.groupid})
                            </option>
                        ))}
                    </select>
                    <div className="select_arrow"></div>
                </div>

                <div className="input_field"> 
                    <label className="desc" htmlFor="start_date">Коли (дата) надіслати запрошення:</label>
                    <div className="input_field"> <span><i aria-hidden="true" className="fa fa-sign-in"></i></span>
                        <input type="date" id="start_date" name="trip-start" value= {dateSt} min="2024-01-01" max="2030-12-31" onChange={handleDateStChange}/>
                    </div>
                </div> */}

                <input style={{marginTop: "15px"}} className="button" type="submit" value={activeParteId !== "NEW" ? 'UPDATE'  : 'SAVE'}/>
                <input style={{marginTop: "15px"}} className="button" type="button" onClick = {() => generateXMLFromData(allData) } value="EXPORT XML"/>

            </form>

            {/* <div style={{top: "0px", left: "500px", position: "absolute", width: "280px", color:"#b3b3b3", padding: "15px"}}>
                PREVIEW
                <div id = {"Group_" + props.ids} className={"card3 "}  style={{ backgroundColor: colorB, margin: "15px"}}>
                    <div className="dclbl04b" style={{ color: colorT}} >{roleId}</div>
                    <div className="dclbl05b" style={{ color: colorT}} >{email}</div>
                </div>
            </div> */}
        </div> 
    );
}

export function InviteList(props) {
    //WELCOM PAGE
    const [imgIco, setImgIco] = useState();
    //const dispatch = useDispatch();

    const openLinkURL = (url) => {
        props.selid (props.json.ID)
    }

    // const iconLink = () => {
    //     setImgIco(tmpimg)     
    // }

    // useEffect(() => {
    //     iconLink()
    // }, []); // AUTOLOAD

    return (
        <li className="lisinmarker" >
            <figure className="userlist"  onClick={openLinkURL} >
                <span className="linenumero">{props.index}</span>
                <span className="outdate"><i className="fa fa-share-square-o" ></i>&nbsp;&nbsp;&nbsp;{props.json.CHECKIN}</span>
                <span className="acdate"><i className="fa fa-check-square-o" ></i>&nbsp;&nbsp;&nbsp;{props.json.CHECKOUT}</span>

                <span className="status">
                    {/* {props.json.CLIENTE == 0 && <i className="fa fa-exclamation-circle" style={{color:"blue"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>}
                    {props.json.CLIENTE == 1 && <i className="fa fa-paper-plane" style={{color:"grey"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>} */}
                    {props.json.CLIENTE == props.json.VIAJEROS && <i className="fa fa-check-circle" style={{color:"green"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>}
                    {props.json.CLIENTE > props.json.VIAJEROS && <i className="fa fa-check-circle" style={{color:"red"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>}
                    {props.json.CLIENTE < props.json.VIAJEROS && <i className="fa fa-check-circle" style={{color:"orange"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>}

                    {/* {props.json.CLIENTE == 3 && <i className="fa fa-times-circle" style={{color:"red"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>}
                    {props.json.CLIENTE == 4 && <i className="fa fa-clock-o" style={{color:"orange"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>} */}
                </span> 

                <span className="email"> <i className="fa fa-envelope" style={{color:"green"}}></i>&nbsp;&nbsp;&nbsp;{props.json.NAME}</span>
            
            </figure>
        </li>
    );
}

function SchoolDecks(props) {
    //const { pid, cid } = useParams() // URL parametro  :cr/:pr/:gr/:id
    const navigate = useNavigate();

    const activeProjectId = localStorage.getItem('projectid');
    const activeParteId = localStorage.getItem('parteid');

    const [isVisible, setIsVisible] = useState(false); // Стан для відображення/приховування
    const [isVisibleB, setIsVisibleB] = useState(true); // Стан для відображення/приховування

    const [inviteList, setInviteList] = useState([]); // IMPORT Group / Course
    
    const apiEndpointInvite = `/vut/getViajerosList/${activeParteId}`;
    const { data: JSONInviteList, loading: loadingInvite, error: ErrorInvite } =  useApiData('GET', apiEndpointInvite);

    useEffect(() => { 
        if (JSONInviteList ) {
            setInviteList(JSONInviteList.VIAJEROS
            )
            console.log ("JSONInviteList", JSONInviteList.VIAJEROS
            )
        }
    }, [JSONInviteList]); // AUTOLOAD


    const toggleVisibility = () => {
        setIsVisible(!isVisible); // Змінити значення стану на протилежне
    };

    const handleHome = (childValue) => {
        navigate(-1);
    };

    useEffect(() => {
        // перевірити валідність токену
        if (activeParteId == "NEW"){
            setIsVisible(true);
            setIsVisibleB(false);
          }
      }, [activeParteId]); // AUTOLOAD
  
    const handlePartedValue = (childValue) => {
        localStorage.setItem('viajeroid', childValue)
        navigate(`/parteviajero`);
    };

    const handleViajeroValue = (childValue) => {
        localStorage.setItem('viajeroid', "NEW")
        navigate(`/parteviajero`);
    };

    return (
        <div>
            <div>
                <div className="project_urls"  >
                    <div className="aButtonClassBack" onClick={handleHome}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>
                    <div className="cLabelClassHeaderBar">Anadir un Parte de los Viajeros {activeParteId + " / " + activeProjectId}</div>
                    {/* <div className="aButtonClassAdd" onClick={handleHome}><i className="fa fa-plus-square-o" aria-hidden="true"></i>E</div>
                    <div className="aButtonClassDelete" ><i className="fa fa-trash-o" aria-hidden="true"></i>A</div>
                    <div className="aButtonClassImport" onClick={handleHome}><i className="fa fa-upload" aria-hidden="true"></i>D</div> */}
                    {isVisibleB && (<div className="aButtonClassConfig" onClick={toggleVisibility}><i className="fa fa-cog" aria-hidden="true"></i>E</div>)}
                </div>
                    {/* <DeckSteps /> */}
                    {isVisible && ( <DeckForm pid = {activeProjectId} parteid = {activeParteId}/>)}
                    {/* <MDEditor.Markdown className='PreviewPageInvite' source={htmlContent} /> */}
            
                        <div>
                                <div className="project_urls"  >
                                    {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                                    <div className="cLabelClassHeaderBar">VIAJEROS </div>
                                </div>
            
                                <ul className="grid_invite_user">
                                        {inviteList && inviteList.map((itemg, index) => ( 
                                            <InviteList 
                                                key = {index} 
                                                index = {index+1}
                                                json={itemg} 
                                                selid={handlePartedValue} // Click Function
                                            />
                                        ))} 
                                </ul>    
                            </div>

                            <input style={{marginTop: "15px"}} onClick={handleViajeroValue} className="button" value={activeParteId !== "NEW" ? 'ADD'  : 'SAVE'}/>

            </div>
        </div>
    );
}



export default SchoolDecks;
