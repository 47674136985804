import React, {useEffect,useState} from 'react';
import { Routes, Route } from "react-router-dom";
import { Outlet,  useNavigate,  useLocation, useSearchParams } from "react-router-dom"; 
import { useSelector } from 'react-redux';
import { GetDeviceID } from "./firebase"

import './App.css';
import './page/style_index.css';
import './page/style_school.css';
import './components/cards/style.css';

// PAGES
import Index from './page/index';
import FirstRun from './page/firstrun';
// import Home from './page/home';
// import Documentation from './page/documentation';
import NoPage from './page/nopage';
// import ProjectMain from './page/project_main';
import LoginSSO from './page/oauth2';


//Projects
import ProjectHome from './page/projecthome';
import AddProject from './page/index_add_project';
//APP PAGES - ANKI
import LoginSSO_APP from './page/APP_ANKI/app_oauth2';
import IndexApp from './page/APP_ANKI/app_index_main';
// import ProjectsApp from './page/APP_ANKI/app_index_projects';
import ProjectsApp from './page/APP_ANKI/app_index_school.jsx';
import ClassApp from './page/APP_ANKI/app_index_class';
import NotificationApp from './page/APP_ANKI/app_index_notification';
import UsersInClassApp from './page/APP_ANKI/app_index_users';
import ExitUserApp from './page/APP_ANKI/app_index_exit';
import PlayerApp from './page/APP_ANKI/app_index_player';
import ChatsApp from './page/APP_ANKI/app_index_chats';
import PerfilApp from './page/APP_ANKI/app_index_perfil';
import RunAnkiApp from './page/APP_ANKI/app_anki_run';
import Invite from './page/APP_ANKI/app_invite';
import IndexDeck from './page/APP_ANKI/app_index_deck';
import AppFirstRun from './page/APP_ANKI/app_firstrun.jsx';

import QRcode from './page/APP_ANKI/app_qr.jsx';
import QRscaner from './page/APP_ANKI/app_qr_scaner.jsx';
import LoginQR from './page/APP_ANKI/app_oauth2_qr.jsx';


// import TouchSlide from './page/touch_list.jsx';
import TouchList from './page/touch_slide.jsx';

// ZOOM
// import ZoomApp from './page/ZOOM/index.jsx';


// PAGES
  // import AnkiListCard from './components/AnkiListCard/list';
  // import School_Classes from './page/school_classes';
  // import School_Add_Classes from './page/school_add_deck';
  import InviteAdd from './page/school_add_invite';
  import InviteParte from './page/WEB_VUT/parte_add_invite';
  import InviteViajero from './page/WEB_VUT/parte_add_viajero.jsx';
  import PublicParteViajero from './page/WEB_VUT/public_parte_viajero.jsx';

  import SchoolClasses from './page/school_classes';
  import SchoolDecks from './page/WEB_SCHOOL/decks/school_decks.jsx';
  import School_Add_Deck from './page/school_add_deck';
  import School_Add_Class from './page/school_add_class.jsx';
  import School_Student_Card from './page/WEB_SCHOOL/school_add_class';

// ANKI
  import AnkiMain from './page/school_anki_main';
  import AnkiRun from './page/school_anki_run';

// ICONS 
import iconHome from './img/iconHome.png';  //sadmin.png avatar.jpeg
import iconHome_s from './img/iconHome_s.png';  //sadmin.png avatar.jpeg
import iconCards from './img/iconCards.png';  //sadmin.png avatar.jpeg
import iconThemas from './img/iconThemas.png';  //sadmin.png avatar.jpeg
import iconChat from './img/iconChat.png';  //sadmin.png avatar.jpeg
import iconPerfil from './img/iconPerfil.png';  //sadmin.png avatar.jpeg




// COMPONENTS
  import NavBar from './components/navbar/navbar';

const AppMenus = (props) => {
  // ROUTER
  const navigate = useNavigate();
  const [userprojects, setProjects] = useState(""); // IMPORT USER
  const [userprojoined, setProjoined] = useState(); // IMPORT USER
  // IMPORT from REDUX
  const usr_email = useSelector((state)=> state.counter.user.email); // email de usuario
  const user_usremail = localStorage.getItem('usremail');
  const user_nickname = localStorage.getItem('usrname');

  // const counter = useSelector((state)=> state.counter.counter);
  const appid = useSelector((state)=> state.application.app.subid);
  const sidemenu = useSelector((state)=> state.application.app.sidemenu);
  // const uname = useSelector((state)=> state.application.app.name);
  const pagejson = useSelector((state)=> state.application.app.page);

  let location = useLocation();

  useEffect(() => {
    // localStorage.setItem('TokenDevice', TokenDevice)

    console.log(localStorage);

    
    GetDeviceID()
      .then(token => {
        // Ваша логіка з використанням отриманого токену
        localStorage.setItem('TokenDevice', token)
      })
      .catch(error => {
        console.error('Помилка TokenDevice:', error);
        // Обробка помилки
      });

    const TokenDevice = localStorage.getItem('TokenDevice');

    const userData = {
      email: usr_email,
      token_device: TokenDevice
    };

    const userDataQR = {
      nickname: user_usremail,
      token_device: TokenDevice
    };

    console.log('APP DECKTOP TokenDevice:', TokenDevice);

    fetch( user_usremail === user_nickname ? 'https://www.nebo.inupline.com/api/web/loginqr':'https://www.nebo.inupline.com/api/web/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'inLine 234234fe23423ewfedf244132@3aws23',
          },
          body: JSON.stringify(user_usremail === user_nickname ?userDataQR: userData ),
      })
    .then(response => response.json())
    .then(response => {
        if (response.status===200) {
          //console.log("APP->setProjects", response)
          setProjects(response.project) //joined
          setProjoined(response.joined)
          localStorage.setItem('login_token', response.login_token)
          //console.log("APP.JS->api/login", response.project)
        }
    }); }, [usr_email,location]); // AUTOLOAD


  const scrollToBottom = () => {
    // Bottom for Up Page
    const element = document.getElementById("body");
    element.scroll(50, 10);
  }

  function OpenLink(event) {
    // Open link in APP usando React Router from HTML importing
    var tbn = document.getElementById(event.target.id)

    var page = tbn.getAttribute('data-link')
    navigate(page, {replace: true});

    console.log(event)
    //navigate('/ProjectMain', {replace: true});
  }

  const getButtonId = (event) => {
    // Scanear Events in iframe
    // OnClick tipo "btm"
    if (event.target.name==="btm") { document.getElementById(event.target.id).onclick = (e) => OpenLink(e);}
  }

  return (
    <>
      {/* MENUS */}
      <NavBar 
        caption={""} 
        usermenu = {pagejson} 
        //UserInfo = {HomePage.user} 
        AppID={appid} 
        SideMenu={sidemenu}
        modo={props.modo}
        //PageInfo = {HomePage.homepage}
        json_project = {userprojects}
        userprojoined= {userprojoined}>
      </NavBar>
      
      {/* BODY */}
      {/* <div className="body" id="body" onMouseMove={(e) => getButtonId(e)}> */}
      <div className="body" id="body">
        <Outlet />
      </div>
    </>
  )
};

// LoginSSO
function App() {
  // const [HomePage, setHomePage] = useState(JSON_HomePage); // IMPORT USER  modo={1} 
  const appid = useSelector((state)=> state.application.app.subid); //id de icono
  const usr_email = useSelector((state)=> state.counter.user.email); // email de usuario
  //const usr_email = localStorage.getItem('usremail');
  const [brandHue, setBrandHue] = useState("#1E1E1E"); // Color fondo
  // Визначаємо, чи це мобільний пристрій
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const user_leng_ui = localStorage.getItem('user_leng_ui');

  const [searchParams] = useSearchParams();
  const join_code = searchParams.get("code"); // "text"

  useEffect(() => {
    console.log("usr_email", usr_email) 
    console.log("user_leng_ui", user_leng_ui) 

    if (!user_leng_ui){localStorage.setItem('user_leng_ui', 0)}

  }, [user_leng_ui]); // AUTOLOAD


 
  
  if (isMobile) {
      // Якщо це мобільний пристрій console.log("MODO Mobile")
      return (
        <div>
          {/* <style>
        {`
          :root {
            --body_backcolor: #1E1E1E;
          }  
        `}
      </style> */}
          <Routes>
              <Route path="/vut/:projectId/:reservaId" element={<PublicParteViajero />} />
              {/* <Route path="/parteinvite" element={<InviteParte />} /> */}
              <Route path="/parteviajero" element={<InviteViajero />} />
              {/* <Route path="/login" element={<LoginQR />} /> */}


              <Route path="/" element={usr_email ? <AppMenus_APP/> : <LoginSSO_APP code={join_code} /> }> 
              {/* <Route index element={<IndexApp InfoPage={appid} email={usr_email} />} /> */}
              {/* <Route path="/firstrun" element={<FirstRun />} />
              <Route path="/projects" element={<ProjectsApp />} />
              <Route path="/notifications" element={<NotificationApp />} />
              <Route path="/usersinclass" element={<UsersInClassApp />} />
              <Route path="/exitapp" element={<ExitUserApp />} />
              <Route path="/playerlist" element={<PlayerApp />} />
              <Route path="/chatrooms" element={<ChatsApp />} />
              <Route path="/userperfil" element={<PerfilApp />} />
              <Route path="/deckid" element={<IndexDeck />} />
              <Route path="/invite/:invid" element={<Invite />} />

              <Route path="/TouchList" element={<TouchList />} />
              <Route path="/qradd" element={<QRcode />} />
              <Route path="/qrjoin" element={<QRscaner />} />

              <Route path="/class/:pid" element={<ClassApp />} />
              <Route path="/anki/:pid" element={<AnkiMain />} />
              <Route path="/anki/run" element={<RunAnkiApp />} />
              <Route path="/projects/new" element={<AddProject />} />
              <Route path="/projects/:pid" element={<ProjectHome />} />
              <Route path="/projects/:pid/:pname" element={<ProjectHome />} />
              <Route path="/projects/:pid/:pname/:cid" element={<ProjectHome />} /> */}
              
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </div>
    );
  } else {
      // Якщо це ПК або планшет console.log("MODO DesktopTable")
      return (
        <Routes>
            <Route path="/vut/:projectId/:reservaId" element={<PublicParteViajero />} />
            <Route path="/parte/:reservaId" element={<PublicParteViajero />} />
            <Route path="/" element={usr_email ? <AppMenus/> : <LoginSSO /> }> 
            <Route index element={<Index InfoPage={appid} email={usr_email} />} />
  
            {/* <Route path="home" element={<Home />} />  */}
            {/* <Route path="oauth2" element={<LoginSSO />} /> */}
            {/* <Route path="demo" element={<DemoAPP />} /> 
            <Route path="test" element={<TestSQL />} /> 
            <Route path="/companys/:cr" element={<CompanyMain />} />
            <Route path="/Documentation" element={<Documentation />} />
            <Route path="/settings" element={<Settings />} /> 
            <Route path="/traductor" element={<Traductor />} /> 
            <Route path="/anki" element={<AnkiListCard />} />  */}
            {/* <Route path="/vchat" element={<ZoomApp />} /> */}


             {/* ПРОГРАМА ДЛЯ НАВЧАННЯ */}
            <Route path="/parteinvite" element={<InviteParte />} />
            <Route path="/parteviajero" element={<InviteViajero />} />

            <Route path="/invite" element={<InviteAdd />} />
            <Route path="/students" element={<School_Student_Card />} />

            <Route path="/firstrun" element={<FirstRun />} />
            <Route path="/anki/:pid" element={<AnkiMain />} />
            <Route path="/anki/run" element={<AnkiRun />} />
            <Route path="/projects/new" element={<AddProject />} />
            <Route path="/projects" element={<ProjectHome />} />
            <Route path="/classes" element={<SchoolClasses />} />
            <Route path="/decks" element={<SchoolDecks />} />
            <Route path="/projects/adddeck" element={<School_Add_Deck />} />
            <Route path="/projects/addclass" element={<School_Add_Class />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
    );
  }
}

export default App;

const AppMenus_APP = (props) => {
  const navigate = useNavigate();
  const selMenu = localStorage.getItem('selMenu');
  const [selectedBox, setSelectedBox] = useState(selMenu);

  const usr_email = useSelector((state)=> state.counter.user.email); // email de usuario
  //const selMenu = localStorage.getItem('selMenu');

  let location = useLocation();
  //console.log(location.pathname) 

  useEffect(() => {
    // localStorage.setItem('TokenDevice', TokenDevice)

    GetDeviceID()
      .then(token => {
        // Ваша логіка з використанням отриманого токену
        localStorage.setItem('TokenDevice', token)
      })
      .catch(error => {
        console.error('Помилка TokenDevice:', error);
        // Обробка помилки
      });

    const TokenDevice = localStorage.getItem('TokenDevice');

    const userData = {
      email: usr_email,
      token_device: TokenDevice
    };

    console.log('APP MOBILE TokenDevice:', TokenDevice);

    fetch('https://www.nebo.inupline.com/api/web/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'inLine 234234fe23423ewfedf244132@3aws23',
          },
          body: JSON.stringify(userData),
      })
    .then(response => response.json())
    .then(response => {
        if (response.status===200) {
          localStorage.setItem('login_token', response.login_token)

          //console.log("APP->setProjects", response)
          //setProjects(response.project) //joined
          //setProjoined(response.joined)
          
          //console.log("APP.JS->api/login", response.project)
        }
    }); }, [usr_email,location]); // AUTOLOAD

  const OPEN_Main = () => {
    setSelectedBox('main');
    localStorage.setItem('selMenu', 'main')
    navigate(`/`);
  };

  const OPEN_Projects = () => {
    setSelectedBox('projects');
    localStorage.setItem('selMenu', 'projects')
    navigate(`/projects`);
  };

  const OPEN_Player = () => {
    setSelectedBox('player');
    localStorage.setItem('selMenu', 'player')
    navigate(`/playerlist`);
  };

  const OPEN_Chat = () => {
    setSelectedBox('chat');
    localStorage.setItem('selMenu', 'chat') 
    navigate(`/chatrooms`);
  };

  const OPEN_Perfil = () => {
    setSelectedBox('perfil');
    localStorage.setItem('selMenu', 'perfil')
    navigate(`/userperfil`);
  };

  const handleloadurl = (id, dateupdate) => {
    const cacheBuster = new Date().getTime(); // Перетворіть в мілісекунди
    const activeProjectId = localStorage.getItem('projectid');

    console.log("fondo cacheBuster", cacheBuster)

    return `https://www.nebo.inupline.com/img/projects/${activeProjectId}/fondo.jpeg`;
  }
  
  return (
    <>
      <div className="bodyApp" id="body" style={{background: `url(${handleloadurl()})`, backgroundSize: 'cover' }}>
        <div className="bodyApp" id="body" style={{backgroundColor: `#00000096`}}>
          <Outlet />
        </div>
      </div>

      {location.pathname === "/firstrun" || location.pathname === "/anki/run" || location.pathname === "/notifications" || location.pathname === "/qradd" || location.pathname === "/qrjoin" ?   null : <div className='BoxMainMenu'>
        <div className={`Box1 ${selectedBox === 'main' ? 'select' : ''}`} onClick={(e) => {OPEN_Main(e)}}><img src={selectedBox === 'main' ? iconHome_s : iconHome } alt="logo "/></div>
        <div className={`Box1 ${selectedBox === 'projects' ? 'select' : ''}`} onClick={(e) => {OPEN_Projects(e)}}><img className= "" src={iconCards} alt="logo "/><i className="fa fa-book" aria-hidden="true" ></i></div>
        <div className={`Box1 ${selectedBox === 'player' ? 'select' : ''}`} onClick={(e) => {OPEN_Player(e)}}><img className= "" src={iconThemas} alt="logo "/><i className="fa fa-bar-chart" aria-hidden="true" ></i></div>
        <div className={`Box1 ${selectedBox === 'chat' ? 'select' : ''}`} onClick={(e) => {OPEN_Chat(e)}}><img className= "" src={iconChat} alt="logo "/><i className="fa fa-comments" aria-hidden="true" ></i></div>
        <div className={`Box1 ${selectedBox === 'perfil' ? 'select' : ''}`} onClick={(e) => {OPEN_Perfil(e)}}><img className= "" src={iconPerfil} alt="logo "/><i className="fa fa-user" aria-hidden="true" ></i></div>
      </div>}
    </>
  )
};