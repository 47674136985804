import React , {  useState , useEffect} from 'react';
import { useNavigate, useParams} from "react-router-dom";  
import '../style_school.css';
import {useApiData,IconsColor} from "../../functions/useApiData"

function DeckForm(props) {
    //const navigate = useNavigate();
    const [viajeroValor0, setViajeroValor0] = useState('');                   // Rol
    const [viajeroValor1, setViajeroValor1] = useState('');                   // Rol
    const [viajeroValor2, setViajeroValor2] = useState('');                     // Nombre
    const [viajeroValor3, setViajeroValor3] = useState('');                     // Apellido 1
    const [viajeroValor4, setViajeroValor4] = useState('');                     // Apellido 2
    const [viajeroValor5, setViajeroValor5] = useState('');                     // Tipo Documento
    const [viajeroValor6, setViajeroValor6] = useState('');                    // Número Documento
    const [viajeroValor7, setViajeroValor7] = useState('');                    // Soporte Documento
    const [viajeroValor8, setViajeroValor8] = useState('');                    // Fecha Nacimiento
    // PAGO
    const [viajeroValor9, setViajeroValor9] = useState('');                     // Nacionalidad
    const [viajeroValor10, setViajeroValor10] = useState('');                   // Sexo
    const [viajeroValor11, setViajeroValor11] = useState('');                   // Dirección
    const [viajeroValor12, setViajeroValor12] = useState('');                   // Complementaria
    const [viajeroValor13, setViajeroValor13] = useState('');                   // Código Municipi
    const [viajeroValor14, setViajeroValor14] = useState('');                   // Nombre Municipio
    const [viajeroValor15, setViajeroValor15] = useState('');                   // Código Postal
    const [viajeroValor16, setViajeroValor16] = useState('');                   // País
    const [viajeroValor17, setViajeroValor17] = useState('');                   // Teléfono
    const [viajeroValor18, setViajeroValor18] = useState('');                   // Teléfono 2
    const [viajeroValor19, setViajeroValor19] = useState('');                   // Correo
    const [viajeroValor20, setViajeroValor20] = useState('');                   // Parentesco
    const [viajeroValor21, setViajeroValor21] = useState('');                   // Parentesco

    const [language, setLanguage] = useState(localStorage.getItem('language') || 'es');

    const activeParteId = localStorage.getItem('parteid');
    const activeViajeroId = localStorage.getItem('viajeroid');
    const login_token = localStorage.getItem('login_token');
    //const language = localStorage.getItem('language');
    const activeProjectId = localStorage.getItem('projectid');

    useEffect(() => {
          if (activeProjectId != "NEW"){
            fetch('https://www.nebo.inupline.com/api/web/vut/getVUT/' + activeProjectId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': '555888666999',
                },
            })
                .then(response => response.json())
                .then(response => {
                    
                    if (response.status===200) {
                        console.log("type OK activeViajeroId-" , response.data)
                        setViajeroValor0(response.data.VUTA_HOMENAME);
                        setViajeroValor1(response.data.VUTA_USERNAME);
                        setViajeroValor2(response.data.VUTA_EMAIL);
                        setViajeroValor3(response.data.VUTA_PHONE);
                        setViajeroValor4(response.data.VUTA_ADRESS);
                        setViajeroValor5(response.data.VUTA_NUMCADASTRO);
                        setViajeroValor6(response.data.VUTA_LICENCIA);
                        setViajeroValor7(response.data.VUTA_NUMHOSPEDAJES);
                        setViajeroValor8(response.data.VUTA_DESCRIPCION); //
                        setViajeroValor9(response.data.VUTA_TYPE);
                        setViajeroValor10(response.data.VUTA_ROOMS);
                        setViajeroValor11(response.data.VUTA_INTERNET);
                        setViajeroValor12(response.data.VUTA_MAXCLIENTS);
                        setViajeroValor13(response.data.VUTA_URL);
                        setViajeroValor14(response.data.VUTA_TIMECHEKIN);
                        setViajeroValor15(response.data.VUTA_TIMECHEKOUT);
                        setViajeroValor16(response.data.VUTA_STATUS);
                        // setViajeroValor17(response.data.TELEFONO);
                        // setViajeroValor18(response.data.TELEFONO2);
                        // setViajeroValor19(response.data.EMAIL);
                        // setViajeroValor20(response.data.PARENTESCO);
                        // setViajeroValor21(response.data.STATUS);
                    } else if (response.status===409) {
                        console.log("type KO-activeViajeroId:" , response)
                    }
                });  
            }
        }, [activeProjectId]); // AUTOLOAD
    
    // const [documentsList, setDocumentsList] = useState([]); // DOCUMENTS TYPE
    // const [paisList, setPaisList] = useState([]); // PAIS TYPE
    // const [sexoList, setSexoList] = useState([]); // SEXO TYPE
    // const [ParantescoList, setParantescoList] = useState([]); // SEXO TYPE

    //const apiEndpointInvite = `/vut/getValores`;
    //const { data: JSONInviteList, loading: loadingInvite, error: ErrorInvite } =  useApiData('GET', apiEndpointInvite);

    // useEffect(() => { 
    //     if (JSONInviteList.DOCUMENTS ) {
    //             setDocumentsList(JSONInviteList.DOCUMENTS)
    //             setPaisList(JSONInviteList.PAISES)
    //             setSexoList(JSONInviteList.SEXO)
    //             setParantescoList(JSONInviteList.PARANTESCO)
    //             console.log ("getValores", JSONInviteList)
    //     }
    // }, [JSONInviteList]); // AUTOLOAD

     // Manejadores de cambios para actualizar los estados
     const handleValorChange0 = (e) => {
        setViajeroValor0(e.target.value);
    };
    
     const handleValorChange1 = (e) => {
        setViajeroValor1(e.target.value);
    };

    const handleValorChange2 = (e) => {
        setViajeroValor2(e.target.value);
    };

    const handleValorChange3 = (e) => {
        setViajeroValor3(e.target.value);
    };

    const handleValorChange4 = (e) => {
        setViajeroValor4(e.target.value);
    };

    const handleValorChange5 = (e) => {
        setViajeroValor5(e.target.value);
    };

    const handleValorChange6 = (e) => {
        setViajeroValor6(e.target.value);
    };

    const handleValorChange7 = (e) => {
        setViajeroValor7(e.target.value);
    };

    const handleValorChange8 = (e) => {
        setViajeroValor8(e.target.value);
    };

    const handleValorChange9 = (e) => {
        setViajeroValor9(e.target.value);
    };

    const handleValorChange10 = (e) => {
        setViajeroValor10(e.target.value);
    };

    const handleValorChange11 = (e) => {
        setViajeroValor11(e.target.value);
    };

    const handleValorChange12 = (e) => {
        setViajeroValor12(e.target.value);
    };

    const handleValorChange13 = (e) => {
        setViajeroValor13(e.target.value);
    };

    const handleValorChange14 = (e) => {
        setViajeroValor14(e.target.value);
    };

    const handleValorChange15 = (e) => {
        setViajeroValor15(e.target.value);
    };

    const handleValorChange16 = (e) => {
        setViajeroValor16(e.target.value);
    };

    const handleValorChange17 = (e) => {
        setViajeroValor17(e.target.value);
    };

    const handleValorChange18 = (e) => {
        setViajeroValor18(e.target.value);
    };

    const handleValorChange19 = (e) => {
        setViajeroValor19(e.target.value);
    };

    const handleValorChange20 = (e) => {
        setViajeroValor20(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const userData = {
            //VUTA_ID: "110",
            VUTA_PROJECTID: activeProjectId,
            VUTA_HOMENAME: viajeroValor0,
            VUTA_USERNAME: viajeroValor1,
            VUTA_EMAIL: viajeroValor2,
            VUTA_PHONE: viajeroValor3,
            VUTA_ADRESS: viajeroValor4,
            VUTA_NUMCADASTRO: viajeroValor5,
            VUTA_LICENCIA: viajeroValor6,
            VUTA_NUMHOSPEDAJES: viajeroValor7,
            VUTA_DESCRIPCION: viajeroValor8,
            VUTA_TYPE: viajeroValor9,
            VUTA_ROOMS: viajeroValor10,
            VUTA_INTERNET: viajeroValor11,
            VUTA_MAXCLIENTS: viajeroValor12,
            VUTA_URL: viajeroValor13,
            VUTA_TIMECHEKIN: viajeroValor14,
            VUTA_TIMECHEKOUT: viajeroValor15,
            VUTA_STATUS: viajeroValor16,
            VUTA_PROCESID: "1",
        };

        fetch(`https://www.nebo.inupline.com/api/web/vut/postVUT/${activeProjectId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData)
        })

      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            console.log("postVUT", response)
            //navigate(-1);
            // dispatch(application.setUpdateTime(response.update));
          } else {
            // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
            console.log("postVUT" , response)
            //navigate(-1);
        }
      });
    };

    return (
        <div className="form_0">
            <form onSubmit={handleSubmit}>
                {/* <input type="hidden" name="userid" readOnly={"user_id"} /> */}
                {/* <label className="desc" htmlFor="val1">
                Rol:
                </label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val1" name="val1" type="text" placeholder="VI" value={viajeroValor1} onChange={handleValorChange1} required disabled/>
                </div> */}
                <label className="desc" htmlFor="val0">HOMENAME:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val0" name="val0" type="text" placeholder="HOMENAME" value={viajeroValor0} onChange={handleValorChange0} required/>
                </div>

                <label className="desc" htmlFor="val1">USER:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val1" name="val1" type="text" placeholder="USER" value={viajeroValor1} onChange={handleValorChange1} required/>
                </div>

                <label className="desc" htmlFor="val2">EMAIL:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val2" name="val2" type="text" placeholder="EMAIL" value={viajeroValor2} onChange={handleValorChange2} required/>
                </div>

                <label className="desc" htmlFor="val3">PHONE:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val3" name="val3" type="text" placeholder="PHONE" value={viajeroValor3} onChange={handleValorChange3}/>
                </div>
                

                <label className="desc" htmlFor="val4">ADRESS:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val4" name="val4" type="text" placeholder="ADRESS" value={viajeroValor4} onChange={handleValorChange4} required/>
                </div>

                <label className="desc" htmlFor="val5">NUM CADASTRO:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val5" name="val5" type="text" placeholder="NUM CADASTRO" value={viajeroValor5} onChange={handleValorChange5}/>
                </div>

                <div className="input_field"> 
                    <label className="desc" htmlFor="val6">NUM LICENCIA:</label>
                    <div className="input_field"> <span><i aria-hidden="true" className="fa fa-sign-in"></i></span>
                        <input id="val6" name="val6" type="text" placeholder="NUM LICENCIA" value= {viajeroValor6} onChange={handleValorChange6} required/>
                    </div>
                </div>

                {/* <br></br><b>Address / Dirección</b> <br></br> */}

                <label className="desc" htmlFor="val7">VUTA_NUMHOSPEDAJES:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val7" name="val7" type="text" placeholder="VUTA_NUMHOSPEDAJES" value={viajeroValor7} onChange={handleValorChange7} required/>
                </div>

                <label className="desc" htmlFor="val8">DESCRIPCION:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val8" name="val8" type="text" placeholder="DESCRIPCION" value={viajeroValor8} onChange={handleValorChange8}/>
                </div>

                <label className="desc" htmlFor="val9">TYPE:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val9" name="val9" type="text" placeholder="TYPE" value={viajeroValor9} onChange={handleValorChange9}/>
                </div>

                <label className="desc" htmlFor="val10">ROOMS:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val10" name="val10" type="text" placeholder="ROOMS" value={viajeroValor10} onChange={handleValorChange10} required/>
                </div>

                <label className="desc" htmlFor="val11">INTERNET:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val11" name="val11" type="text" placeholder="INTERNET" value={viajeroValor11} onChange={handleValorChange11} required/>
                </div>

                
                <label className="desc" htmlFor="val12">MAX CLIENTS:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val12" name="val12" type="text" placeholder="MAX CLIENTS" value={viajeroValor12} onChange={handleValorChange12} required/>
                </div>

                <label className="desc" htmlFor="val13">URL:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val13" name="val13" type="text" placeholder="URL" value={viajeroValor13} onChange={handleValorChange13}/>
                </div>

                <label className="desc" htmlFor="val14">TIME CHEKIN:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val14" name="val14" type="text" placeholder="TIME CHEKIN" value={viajeroValor14} onChange={handleValorChange14} required/>
                </div>

                <label className="desc" htmlFor="val15">TIME CHEKOUT:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val15" name="val15" type="text" placeholder="TIME CHEKOUT" value={viajeroValor15} onChange={handleValorChange15} required/>
                </div>

                <label className="desc" htmlFor="val16">STATUS:</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val16" name="val16" type="text" placeholder="STATUS" value={viajeroValor16} onChange={handleValorChange16} required/>
                </div>     

                <input style={{marginTop: "15px"}} className="button" type="submit" value={activeViajeroId !== "NEW" ? 'UPDATE'  : 'SAVE'}/>
            </form>

        </div> 
    );
}

function SchoolDecks(props) {
    //const { pid, cid } = useParams() // URL parametro  :cr/:pr/:gr/:id
    //const navigate = useNavigate();

    const activeProjectId = localStorage.getItem('projectid');
    const activeViajeroId = localStorage.getItem('viajeroid');

    const handleHome = (childValue) => {
        //navigate(-1);
    };

    const toggleClass3 = () => {
        const oldClassName = document.getElementById('Menu03').className;
        if (oldClassName === 'menu2open') {
          const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
          document.getElementById('Menu03').className=newClassName;
          var workPageNew = "body"
          const workPageOld = document.getElementById('body').className;
          if (workPageOld === 'bodylite' ) workPageNew = 'bodycenter'
          if (workPageOld === 'body' ) workPageNew = 'bodyright'
          if (workPageOld === 'bodyright' ) workPageNew = 'body'
          if (workPageOld === 'bodycenter' ) workPageNew = 'bodylite'
          document.getElementById('body').className=workPageNew;
        }
      }

    return (
        <div>
            <div>
                <div className="project_urls"  >
                    <div className="aButtonClassBack" onClick={toggleClass3}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>
                    <div className="cLabelClassHeaderBar">VUT ID {activeProjectId}</div>
                    {/* <div className="aButtonClassAdd" onClick={handleHome}><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassDelete" onClick={() => handleDelDeck(cid)}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassImport" onClick={handleHome}><i className="fa fa-upload" aria-hidden="true"></i></div>
                    <div className="aButtonClassConfig" onClick={handleHome}><i className="fa fa-cog" aria-hidden="true"></i></div> */}
                </div>
                    {/* <DeckSteps /> */}
                    <DeckForm pid = {activeProjectId} />
                    {/* <MDEditor.Markdown className='PreviewPageInvite' source={htmlContent} /> */}
            </div>
        </div>
    );
}

export default SchoolDecks;
