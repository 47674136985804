import React , { useRef, useState , useEffect} from 'react';
import { useNavigate, useParams} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
// import {Card} from "../components/cards/cards";
// import LoadingUI from '../components/loading/loadingui';
import Avatar from '../../img/avatar.jpeg';  //sadmin.png avatar.jpeg temp02.png
import tmpimg from '../../img/temp02.png';  //sadmin.png avatar.jpeg temp02.png
import star_anki from '../../img/star_anki.png';  //sadmin.png avatar.jpeg temp02.png
import flag_es from '../../img/ESCR2.png';  //sadmin.png avatar.jpeg temp02.png
import deck from '../../img/deck.png';  //sadmin.png avatar.jpeg temp02.png
import {useApiData, useLoadApiFoto, IconsColor} from "../../functions/useApiData"
// import ReactDOM from 'react-dom';
import ReactDOM, { createRoot  } from 'react-dom/client';

import UserAva from '../../img/user.png';  //noche.png
import NocheAva from '../../img/noche.png';  //noche.png
import InAva from '../../img/arrow-circle-broken-right.png';  //noche.png
import OutAva from '../../img/arrow-circle-broken-left.png';  //noche.png

// import IconDELETE from '../../img/iconDEL.png';  
// import IconEDIT from '../../img/iconEDIT.png';  

// import IconADD from '../../img/iconADD.png';
// import ImgF003 from '../../img/BFimg/F003.jpg';  //sadmin.png
// import logo from '../../img/logo_company.png';  //sadmin.png

import '../userlist.css'; // Стилі для елемента

import { DeckForm , EditClass} from '../school_add_class' // MODULE SCHOOL

import '../style_school.css';
import './style_vut.css';

import VUT_SETINGS from './setings_vut';


export function StudentsCard(props) {
    
  const openLinkURL = (url) => {
      props.selid (props.ids)
  }

  const calculateNights = (checkIn, checkOut) => {
    // Перетворюємо строки в об'єкти Date
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);
  
    // Обчислюємо різницю в мілісекундах
    const timeDifference = checkOutDate - checkInDate;
  
    // Перетворюємо в кількість діб (одна доба = 24 години = 86400000 мілісекунд)
    const nights = timeDifference / (1000 * 3600 * 24);
  
    // Якщо різниця менша за 1 день, повертаємо 1 ніч (це залежить від вашої логіки)
    return nights < 1 ? 1 : Math.floor(nights)+1;
  };

  return (

      <div id = {"Card_" + props.ids} className={"card_parte card-0"} onClick={openLinkURL} style={props.style}>
              {props.label === "airbnb"  && (
                <div className="airbnb-container">
                  <div className="booking-text">{props.label}</div>
                </div>
              ) }

              {props.label === "booking"  && (
                <div className="booking-container">
                  <div className="booking-text">{props.label}</div>
                </div>
              ) }

              {props.label === "parte"  && (
                <div className="parte-container">
                  <div className="booking-text">{props.label}</div>
                </div>
              ) }

              
              {/* <div className="cButton" onClick={() => ShowMenuApp("appid", props.id)} >...</div> */} 
              {/* <div className="cButtonFlip" onClick={(e) => flipCard(e.target.parentNode.parentNode.id)}><i className="fas fa-bolt">...</i></div> */}
              <div className="sTitle">{props.title}</div>
              <div className="sEmail">{props.email}</div>
              {/* <div className="task__levelUser task__levelCard--0" > DISABLED </div> */}

              {/* {props.label === "parte"&& (  <div className="task__levelUser task__levelCard--1" > OK </div> )} */}
              {props.label === "parte"&& ( <div>
                <img className= "sUser" src={UserAva} referrerPolicy="no-referrer" alt="logo "/>
                <img className= "sNoche" src={NocheAva} referrerPolicy="no-referrer" alt="logo "/>

                <div className="sStatus">{props.clientes} / {props.viajeros}</div>
                <div className="sNoches"> {calculateNights(props.datein, props.dateout)}</div>

                {props.clientes === props.viajeros && ( <div className="task__levelUser task__levelCard--1" > OK </div>)}
                {props.clientes > props.viajeros && ( <div className="task__levelUser task__levelCard--2" > FALTA </div>)}

                <div className="sId">ID: {props.ids}</div>

                <div className="sDateIn">{props.datein.slice(0, 10)}</div>  
                <div className="sDateOut">{props.dateout.slice(0, 10)}</div>

              </div>)}

              {props.label != "parte" && ( <div>
                <img className= "sUser" src={NocheAva} referrerPolicy="no-referrer" alt="logo "/>

                <div className="sStatus"> {props.noches}</div>

                {/* <div className="sId">ID: {props.ids}</div> */}

                <div className="sDateIn">{props.datein}</div>  
                <div className="sDateOut">{props.dateout}</div>

              </div>)}
              <img className= "sInICO" src={InAva} referrerPolicy="no-referrer" alt="logo "/>
              <img className= "sOutICO" src={OutAva} referrerPolicy="no-referrer" alt="logo "/>

              {/* {props.level == 0 && <div className="task__levelUser task__levelCard--0" > DISABLED </div> }
              {props.level == 1 && <div className="task__levelUser task__levelCard--1" > Lv. BASIC </div> }
              {props.level == 2 && <div className="task__levelUser task__levelCard--2" > Lv. MIDDLE </div> }
              {props.level == 3 && <div className="task__levelUser task__levelCard--3" > Lv. ADVANCED </div> }
              {props.level == 4 && <div className="task__levelUser task__levelCard--4" > Lv. NATIVE </div> } */}

              {/* <div className="sA1">{props.A1} X</div> // checkin.slice(0, 10);
              <div className="sA2">{props.A2} C </div>
              <div className="sA3">{props.A3} V</div> */}

              

              

             

          {/* <p className="card__date"><i className="fas fa-times">{props.date}</i></p> */}
      </div>
  );
}


const CalendarWithNavigation = (props) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [hoveredReservation, setHoveredReservation] = useState(null);

  // Функція для отримання даних з API
  useEffect(() => {
      if (props.data) {
        setEvents(props.data);
      } 
  }, [props.data]);

  // Функція для отримання масиву місяців для відображення
  const getMonthsToDisplay = () => {
    const months = [];
    for (let i = -1; i <= 1; i++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth() + i);
      months.push({
        year: date.getFullYear(),
        month: date.getMonth(),
      });
    }
    return months;
  };

  // Функції навігації
  const handleNextMonths = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 3));
  };

  // Функції навігації
  const handleNextMonths1 = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
  };

  const handlePreviousMonths = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 3));
  };

  const handlePreviousMonths1 = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
  };

  const handleCurrentMonth = () => {
    setCurrentDate(new Date());
  };

  const getDateStyle = (date) => {
    const formattedDate = date.toISOString().split("T")[0].replace(/-/g, "");
    let style = {};
  
    // Шукаємо збіги, коли поточна дата є dateout одного бронювання і datein іншого
    const isCheckInOutDate = events.some(
      (event1) => 
        event1.dateout === formattedDate && 
        events.some((event2) => event2.datein === formattedDate && event1.UID !== event2.UID)
    );
  
    for (const { datein, dateout, platform, UID } of events) {
      const startDate = datein;
      const endDate = dateout;
      const isHovered = hoveredReservation === UID;
  
      // Підсвічення всього діапазону при наведенні
      if (isHovered && formattedDate >= startDate && formattedDate <= endDate && !isCheckInOutDate) {
        style.backgroundColor = platform === "airbnb" ? "rgba(255, 0, 0, 1)" : "rgba(0, 0, 255, 1)";
        style.borderRadius = "0";
        style.borderWidth = "2px 0";
        style.color = "rgb(255, 255, 255)";
      } else if (formattedDate > startDate && formattedDate < endDate) {
        style.backgroundColor = platform === "airbnb" ? "#FF385C" : "#002868";
        style.borderRadius = "0";
        style.borderWidth = "2px 0";
        style.color = "rgb(255, 255, 255)";
      }

      // Якщо дата є одночасно і заїздом, і виїздом (збіг бронювань)
      if (isHovered && isCheckInOutDate && formattedDate === dateout) {
        style.borderRadius = "0 50% 50% 0";
        style.borderWidth = "2px 0";
        style.color = "rgb(255, 255, 255)";
        style.backgroundColor = platform === "airbnb" ? "rgba(255, 0, 0, 1)" : "rgba(0, 0, 255, 1)";

      } else if ( !isCheckInOutDate && formattedDate === dateout) { 
        //console.log( "tst",isCheckInOutDate,startDate ,endDate, dateout, formattedDate )
        // style.borderRadius = "50% 0 0 50%";
        // style.borderWidth = "2px 0";
        // style.color = "rgb(255, 255, 255)";
        // style.backgroundColor = platform === "airbnb" ? "rgba(255, 0, 0, 1)" : "rgba(0, 0, 255, 1)";

      } else if (isCheckInOutDate && formattedDate === dateout) {
        style.background = platform === "airbnb"
        ? "linear-gradient(135deg, #FF385C 40%, #fff 25%, #fff 55%, #FF385C 55%)"
        : "linear-gradient(135deg, #002868 40%, rgba(0, 0, 0, 0)  25%, rgba(0, 0, 0, 0)  55%, #002868 55%)";
        style.color = "rgb(255, 255, 255)";
        style.borderRadius = "0%";
        //console.log(isCheckInOutDate,startDate ,endDate, dateout, formattedDate )
      }

  
      if (isHovered && formattedDate === startDate && !isCheckInOutDate) {
          style.borderRadius = "50% 0 0 50%";
          style.borderWidth = "2px 0";
          style.color = "rgb(255, 255, 255)";
          style.backgroundColor = platform === "airbnb" ? "rgba(255, 0, 0, 1)" : "rgba(0, 0, 255, 1)";
          //console.log(isCheckInOutDate,startDate ,endDate, dateout, formattedDate )

        } else if (formattedDate === startDate && !isCheckInOutDate) {  
          style.borderRadius = "50% 0 0 50%";
          style.borderWidth = "2px 0";
          style.color = "rgb(255, 255, 255)";
          style.backgroundColor = platform === "airbnb" ? "#FF385C" : "#002868";

        }
  
      if (isHovered && formattedDate === endDate && !isCheckInOutDate) {
          style.borderRadius = "0 50% 50% 0";
          style.borderWidth = "2px 0";
          style.color = "rgb(255, 255, 255)";
          style.backgroundColor = platform === "airbnb" ? "rgba(255, 0, 0, 1)" : "rgba(0, 0, 255, 1)";
          //console.log(isCheckInOutDate,startDate ,endDate, dateout, formattedDate )

        } else if (formattedDate === endDate && !isCheckInOutDate) { 
          style.borderRadius = "0 50% 50% 0";
          style.borderWidth = "2px 0";
          style.color = "rgb(255, 255, 255)";
          style.backgroundColor = platform === "airbnb" ? "#FF385C" : "#002868";
        }
    }
  
    return style;
  };
    
// Рендер одного місяця
const renderMonth = ({ year, month }) => {
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const firstDay = new Date(year, month, 1).getDay();

  return (
    <div key={`${year}-${month}`} className="month">
      <h3>{new Date(year, month).toLocaleString("default", { month: "long", year: "numeric" })}</h3>
      <div className="calendar-grid">
        {Array.from({ length: firstDay }, (_, i) => (
          <div key={`empty-${i}`} className="empty"></div>
        ))}
        {Array.from({ length: daysInMonth }, (_, day) => {
          const date = new Date(year, month, day + 2);
          const style = getDateStyle(date);
          const formattedDate = date.toISOString().split("T")[0].replace(/-/g, "");

          const event = events.find(e => e.datein <= formattedDate && e.dateout >= formattedDate);

          return (
            <div
              key={date}
              className="day"
              style={style}
              onMouseEnter={() => setHoveredReservation(event?.UID || null)}
              onMouseLeave={() => setHoveredReservation(null)}
              title={event ? `Reservation: ${event.SUMMARY}` : "Available"}
            >
              {day + 1}
            </div>
          );
        })}
      </div>
    </div>
  );
};

  return (
    <div className="calendar-container">
      <div className="navigation">
        <button onClick={handlePreviousMonths}>-3 Meses</button>
        <button onClick={handlePreviousMonths1}>-1 Meses</button>
        <button onClick={handleNextMonths1}>+1 Meses</button>
        <button onClick={handleNextMonths}>+3 Meses</button>
        <button onClick={handleCurrentMonth}>Mes Actual</button>
      </div>
      <div className="months">
        {getMonthsToDisplay().map(renderMonth)}
      </div>
    </div>
  );
};

  

function School(props) {
    const [SchoolArray, setSchoolArray] = useState([]); // IMPORT Group / Course
    const [SchoolClass, setSchoolClass] = useState([]); // IMPORT Class
    const [SchoolDecks, setDecks] = useState([]); // IMPORT DECKS
    const [SchoolStudents, setStudents] = useState([]); // IMPORT DECKS

    const [ProjectURL, setProjectURL] = useState([]); // IMPORT URL

    const apiEndpointAPI2 = `/project/url/${props.pid}`;

    const { data: UrlData, loading: loading2, error: APIError2 } =  useApiData('GET', apiEndpointAPI2);

    const [selectedID, setSelectedID] = useState(null);

    //const login_token = useSelector((state)=> state.counter.user.login_token);
    const login_token = localStorage.getItem('login_token');

    const navigate = useNavigate();

    const [userRole, setUserRole] = useState(0);

    const [inviteList, setInviteList] = useState([]); // IMPORT Group / Course

    const apiEndpointInvite = `/vut/getPartesViajerosList/${props.pid}`;
    const { data: JSONInviteList, loading: loadingInvite, error: ErrorInvite } =  useApiData('GET', apiEndpointInvite);


    const [events, setEvents] = useState([]);

  // Функція для отримання даних з API
  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        const response = await fetch("https://www.nebo.inupline.com/api/web/vut/postUrlCalendar", {
          method: "POST",
          headers: {
            Authorization: "555888666999",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            urls: [
              "https://www.airbnb.es/calendar/ical/1164486650741867310.ics?s=a333fb2e4e360c34d98d2ea2ecf869d5",
              "https://ical.booking.com/v1/export?t=114441cb-bb81-4e1c-8695-6dfd6136ab9c",
            ],
          }),
        });

        const result = await response.json();
        if (result.status === 200) {
          setEvents(result.data);
          console.log("Calendar data:", result.data);
        } else {
          console.error("Error fetching calendar data:", result);
        }
      } catch (error) {
        console.error("Error fetching calendar data:", error);
      }
    };

    fetchCalendarData();
  }, []);

    useEffect(() => { 
        if (JSONInviteList ) {
            setInviteList(JSONInviteList.PARTE
            )
            console.log ("JSONInviteList", JSONInviteList.PARTE
            )
        }
    }, [JSONInviteList]); // AUTOLOAD

  
        const activeProjectId = localStorage.getItem('projectid');

        const handlePartedValue = (childValue) => {
            localStorage.setItem('parteid', childValue)
            navigate(`/parteinvite`);
        };

        const handleShowAddDeck = (childValue) => {
            navigate(`/projects/adddeck`);
        };

        const handleShowAddClass = (childValue) => {
            //navigate(`/projects/addclass`);

            // ReactDOM.render(<DeckForm pid = {activeProjectId}/>, document.getElementById('UserMenuBoxBODY'));
            const tools = createRoot(document.getElementById('UserMenuBoxBODY'));
            tools.render(<VUT_SETINGS pid = {activeProjectId}/>);
            
            ShowToolsMenu()
        };

        const ShowToolsMenu = () => {
            const oldClassName = document.getElementById('Menu03').className;
            if (oldClassName === 'menu2hide' ) {
                const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
                document.getElementById('Menu03').className=newClassName;
                var workPageNew = "body"
                const workPageOld = document.getElementById('body').className;
                if (workPageOld === 'bodylite' ) {workPageNew = 'bodycenter'}
                if (workPageOld === 'body' ) {workPageNew = 'bodyright'}
                if (workPageOld === 'bodyright' ) {workPageNew = 'body'}
                if (workPageOld === 'bodycenter' ) {workPageNew = 'bodylite'}
                document.getElementById('body').className=workPageNew;
            }
    
            const oldMenuUser = document.getElementById('Menu02').className;
            const closeUserMenu = oldMenuUser === 'menu2open' ? 'menu2hide' : 'menu2hide'
            document.getElementById('Menu02').className=closeUserMenu;
        }

        const handleToolsInvite = (childValue) => {
            console.log("vchat",childValue)
            navigate(`/vchat`);

        };

        const handleAddInvite = (childValue) => {
            localStorage.setItem('parteid', "NEW")
            navigate(`/parteinvite`);
        };

        const handleGoHome = (childValue) => {
            navigate(`/`);
        };

        const convertToDate = (num) => {
          const str = num.toString();
          const year = str.slice(0, 4);
          const month = str.slice(4, 6) - 1; // Місяці нумеруються з 0
          const day = str.slice(6, 8);
        
          const date = new Date(year, month, day);
        
          // Форматуємо дату вручну
          const options = { day: "2-digit", month: "long", year: "numeric" }; // Наприклад, "18 July 2025"
          return date.toLocaleDateString("es-ES", options);
        };

        return (
            <div>
                <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}>  { IconsColor(3, "var(--text_color_menu)","24px") }</div>

                        <div className="cLabelClassHeaderBar">CALENDARIO</div>
                        <div className="aButtonClassImport" onClick={() => handleShowAddClass()}>{ IconsColor(1, "var(--text_color_menu)","24px") }</div>
                        {/* <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div> */}
                        {/* <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassConfig">{ IconsColor(2, "var(--text_color_menu)","24px") }</div>
                    </div>

                    <div className="classDeckslist" >
                        <CalendarWithNavigation data={events}/>
                        {/* <CalendarWithNavigationH data={events}/> */}
                    </div>
                </div>
                 
                <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassHome"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>

                        <div className="cLabelClassHeaderBar">RESERVAS</div>
                        <div className="aButtonClassConfig" onClick={() => handleShowAddDeck()}>{ IconsColor(1, "var(--text_color_menu)","24px") }</div>
                        {/* <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div> */}
                    </div>

                    <div className="reservasgroup" >
                      {events &&
                          [...events] // створюємо копію масиву, щоб уникнути мутації оригінального
                          .sort((a, b) => a.datein - b.datein)
                          .map((itemg, index) => (
                                  <StudentsCard
                                      key={index} // OK
                                      ids={itemg.UID} // OK
                                      title={itemg.platform} // OK - G1 ! => GRUPGROUP.GRUPNAME
                                      label={itemg.platform} // Label ? ! => GRUPGROUP.GRUPLABEL
                                      clientes={0} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                      viajeros={0} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                      noches={itemg.nights} // Fon color ! => GRUPGROUP.GRUPCOLORB 
                                      datein={convertToDate(itemg.datein)} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                      dateout={convertToDate(itemg.dateout)} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                      //selid={handlePartedValue} // Click Function
                                  />
                              ))
                      }
                    </div>    

                </div>

                <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>

                        <div className="cLabelClassHeaderBar">PARTERS</div>
                        <div className="aButtonClassImport" onClick={() => handleAddInvite()}>{ IconsColor(1, "var(--text_color_menu)","24px") }</div>

                        {/* <div className="aButtonClassAdd"><i className="fa fa-plus" aria-hidden="true"></i></div>
                        <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassConfig">{ IconsColor(2, "var(--text_color_menu)","24px") }</div>

                    </div>

                    <div className="reservasgroup" >
                            {inviteList && inviteList.map((itemg, index) => ( 
                                <StudentsCard 
                                    key = {index} // OK
                                    ids={itemg.ID} // OK
                                    title={itemg.REFCONTRATO} // OK - G1 ! => GRUPGROUP.GRUPNAME
                                    label = {"parte"} // Label ? ! => GRUPGROUP.GRUPLABEL
                                    photo={itemg.photo} // OK  ! => GRUPGROUP.GRUPICON
                                    clientes = {itemg.CLIENTE} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    viajeros = {itemg.VIAJEROS} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    datein = {itemg.CHECKIN} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    dateout = {itemg.CHECKOUT} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    selid={handlePartedValue} // Click Function
                                />
                            ))} 
                    </div>    
                </div>

                <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>

                        <div className="cLabelClassHeaderBar">Sobre vivenda</div>
                        <div className="aButtonClassImport" onClick={() => handleAddInvite()}>{ IconsColor(1, "var(--text_color_menu)","24px") }</div>
                        {/* <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div> */}
                        {/* <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassConfig">{ IconsColor(2, "var(--text_color_menu)","24px") }</div>
                    </div>
            </div>
        </div>
    );
}

export default School;
